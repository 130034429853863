import { Vue } from "vue-property-decorator";

/**
 * 画面サイズを判定するユーティリティ
 */
export default class WindowSizeChecker {
  /**
   * 幅がSPか否か
   *
   * @param vue Vueインスタンス
   */
  static isPhone(vue: Vue) {
    return vue.$vuetify.breakpoint.xsOnly;
  }

  /**
   * 幅がタブレット以下か否か
   *
   * @param vue Vueインスタンス
   */
  static isPhoneAndTablet(vue: Vue) {
    return vue.$vuetify.breakpoint.smAndDown;
  }

  /**
   * 幅がタブレット以上か否か
   *
   * @param vue Vueインスタンス
   */
  static isTabletAndPC(vue: Vue) {
    return vue.$vuetify.breakpoint.smAndUp;
  }
}
