/**
 * sessionStorageを管理するラッパークラス
 */
export class SessionStorage {
  /**
   * sessionStorageにjson形式で保存します。
   *
   * @param key sessionStorageのkey
   * @param object sessionStorageに保存するオブジェクト
   */
  static setObject(key: string, object: {}) {
    sessionStorage.setItem(key, JSON.stringify(object));
  }

  /**
   * sessionStorageに保存されているjson形式の値をオブジェクトとして取得します。
   *
   * @param key sessionStorageのkey
   * @param defaultObject 値が保存されていなかった場合のデフォルトオブジェクト
   * @return オブジェクトを返却
   */
  static getObject(key: string, defaultObject = {}) {
    const storageData = sessionStorage.getItem(key);
    return storageData ? JSON.parse(storageData) : defaultObject;
  }

  /**
   * sessionStorageから削除します。
   *
   * @param key sessionStorageのkey
   */
  static removeObject(key: string) {
    sessionStorage.removeItem(key);
  }

  /**
   * sessionStorageをクリアします。
   */
  static clearAll() {
    sessionStorage.clear();
  }
}
