import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as AdminAPI from "@/api/admin";
import * as MenuAPI from "@/api/side-menu";
import { LoginStorage } from "@/store/login-storage";
import {
  SideMenuItem,
  SideMenuResult,
  SubSideMenuItem
} from "@/api/side-menu/response";
import { LoginRequest } from "@/api/admin/request";
import { isSuccess } from "@/api/response";
import { MenuStorage } from "@/store/menu-storage";
import _ from "lodash";
import { SessionStorage } from "@/store/session-storage";
import { BusinessServiceStorage } from "@/store/business-service-storage";

const MODULE_NAME = "admin/admin";

// TODO: IDは暫定
const COMM_MENU_GROUP_ID = "3";

// TODO: IDは暫定
const BOOKING_MENU_GROUP_ID = "9";

// TODO: toは暫定
const NOTIF_MENU_TEMPLATE = {
  menuGroupId: "0",
  menuId: null,
  title: "お知らせ",
  isShowDrawer: false,
  to: null,
  items: [
    {
      menuId: "0",
      title: "お知らせ",
      to: "/information",
      badge: 0
    }
  ]
} as SideMenuItem;

const UNREAD_SUB_MENU_TEMPLATE = {
  menuId: "0",
  title: "トーク未読数",
  to: "/communication",
  badge: 0
} as SubSideMenuItem;

const UNAPPROVED_SUB_MENU_TEMPLATE = {
  menuId: "0",
  title: "予約未承認数",
  to: "/booking",
  badge: 0
} as SubSideMenuItem;

/**
 * ログインAPI（/admin-login）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Admin extends VuexModule {
  // state
  statusCd = 0 as number;
  errorMessage = "" as string;
  menuResult = {} as SideMenuResult;
  notifMenuItem = {} as SideMenuItem;
  accessToken = "" as string;
  kbn = 1 as number;

  // getters
  get isSuccess() {
    return this.statusCd && this.statusCd == 200;
  }

  get getMessage() {
    return this.errorMessage ? this.errorMessage : "";
  }

  get getMenuItems() {
    if (this.menuResult) {
      return this.menuResult.items;
    }
    return [];
  }

  get getNotifMenuItem() {
    return this.notifMenuItem;
  }

  get getAppName() {
    if (this.menuResult) {
      return this.menuResult.appName;
    }
    return "";
  }

  get getAppIconUrl() {
    if (this.menuResult) {
      return this.menuResult.appIcon;
    }
    return "";
  }

  get getShopId() {
    if (this.menuResult) {
      return this.menuResult.shopId;
    }
    return "";
  }

  get getShopName() {
    if (this.menuResult) {
      return this.menuResult.shopName;
    }
    return "";
  }

  get getLoginUserName() {
    if (this.menuResult) {
      return this.menuResult.loginUserName;
    }
    return "";
  }

  get getLoginUserId() {
    if (this.menuResult) {
      return this.menuResult.id;
    }
    return 0;
  }

  get isAccountEditable() {
    if (this.menuResult) {
      return this.menuResult.accountFlg === 1;
    }
    return false;
  }

  get isAdmin() {
    return this.kbn === 0;
  }

  // MutationActions
  @MutationAction
  async login(loginRequest: LoginRequest) {
    const loginResponse = await AdminAPI.login(loginRequest);
    if (isSuccess(loginResponse)) {
      LoginStorage.save(loginResponse, loginRequest.remember);
      MenuStorage.save(loginResponse.results);
      return {
        statusCd: loginResponse.statusCd,
        errorMessage: loginResponse.message,
        menuResult: loginResponse.results as SideMenuResult,
        accessToken: loginResponse.results.accessToken,
        kbn: loginResponse.results.kbn
      };
    } else {
      return {
        statusCd: loginResponse.statusCd,
        errorMessage: loginResponse.message
      };
    }
  }

  @MutationAction
  async logout() {
    const response = await AdminAPI.logout();
    if (isSuccess(response)) {
      // サービス事業者情報のセッションストレージはクリアしない
      // 管理ツールの場合、SessionStorageが消える遷移が存在するのでLocalStorageから取得
      const businessService = BusinessServiceStorage.getLocalStorage();
      LoginStorage.removeAll();
      MenuStorage.removeAll();
      SessionStorage.clearAll();
      BusinessServiceStorage.removeLocalStorage();
      BusinessServiceStorage.setSessionStorage(businessService);
      return {
        statusCd: response.statusCd,
        errorMessage: response.message,
        menuResult: {} as SideMenuResult,
        notifMenuItem: {},
        accessToken: "",
        kbn: 1
      };
    } else {
      return {
        statusCd: response.statusCd,
        errorMessage: response.message,
        menuResult: this.menuResult,
        notifMenuItem: {},
        accessToken: this.accessToken,
        kbn: this.kbn
      };
    }
  }

  @MutationAction
  async menu() {
    const response = await MenuAPI.get();
    let menuResult = this.menuResult;
    if (isSuccess(response)) {
      MenuStorage.save(response.results);
      menuResult = response.results;
    }

    let notifMenuItem = _.cloneDeep(NOTIF_MENU_TEMPLATE);
    if (
      menuResult.items.some(value => value.menuGroupId == COMM_MENU_GROUP_ID)
    ) {
      const response = await MenuAPI.getUnreadCount();
      if (isSuccess(response)) {
        let subMenuItem = _.cloneDeep(UNREAD_SUB_MENU_TEMPLATE);
        subMenuItem.badge = response.unreadCount;
        if (notifMenuItem.items) {
          notifMenuItem.items.push(subMenuItem);
        }
      }
    }
    if (
      menuResult.items.some(value => value.menuGroupId == BOOKING_MENU_GROUP_ID)
    ) {
      const response = await MenuAPI.getUnapprovedCount();
      if (isSuccess(response)) {
        let subMenuItem = _.cloneDeep(UNAPPROVED_SUB_MENU_TEMPLATE);
        subMenuItem.badge = response.unapproveCount;
        if (notifMenuItem.items) {
          notifMenuItem.items.push(subMenuItem);
        }
      }
    }
    MenuStorage.saveNotifMenuItem(notifMenuItem);

    return {
      statusCd: response.statusCd,
      errorMessage: response.message,
      menuResult,
      notifMenuItem
    };
  }

  @MutationAction
  async restore() {
    const accessToken = LoginStorage.getAccessToken();
    const kbn = LoginStorage.getKBN();
    const menuResult = MenuStorage.getMenuResult();
    const notifMenuItem = MenuStorage.getNotifMenuItem();
    return {
      menuResult,
      notifMenuItem,
      accessToken,
      kbn
    };
  }

  /**
   * セッションストレージやローカルストレージの内容を削除するメソッド
   * ログイン画面初期表示 or 401エラー（※）時に呼ばれる
   * ※アクセストークン関連エラー or 操作権限エラー時に401エラー発生
   */
  @MutationAction
  async clear() {
    // バックエンドの全APIから処理遷移する可能性があり影響範囲の特定が困難であるため、
    // SessionStorageとLocalStorageの両方をチェックする。
    let businessService = BusinessServiceStorage.getSessionStorage();
    if(Object.keys(businessService).length === 0) { //空オブジェクトのチェック
      businessService = BusinessServiceStorage.getLocalStorage();
    }
    LoginStorage.removeAll();
    MenuStorage.removeAll();
    SessionStorage.clearAll();
    BusinessServiceStorage.removeLocalStorage();
    BusinessServiceStorage.setSessionStorage(businessService);
    return {
      statusCd: 0,
      errorMessage: "",
      menuResult: {} as SideMenuResult,
      notifMenuItem: {},
      accessToken: "",
      kbn: 1
    };
  }
}

export default getModule(Admin);
