import {
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";

const MODULE_NAME = "loading";

/**
 * 通信中を管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Loading extends VuexModule {
  private static readonly EXCEPTED_LOADING_URLS = [
    "/get-side-menu",
    "/get-unread-count",
    "/get-unapprove-count"
  ];
  // state
  loading = false;

  // getters
  get isLoading() {
    return this.loading;
  }

  // MutationActions
  @Mutation
  startLoading(url: string) {
    if (!Loading.EXCEPTED_LOADING_URLS.includes(url)) {
      this.loading = true;
    }
  }

  @Mutation
  endLoading() {
    this.loading = false;
  }
}

export default getModule(Loading);
