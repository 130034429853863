import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import router from "@/router/index";
import { cloneDeep } from "lodash";
import { LoginStorage } from "@/store/login-storage";
import Admin from "@/store/admin/admin";
import Loading from "@/store/loading";
import { DEFAULT_TABLE_OPTIONS } from "@/api/request";
import iconv from "iconv-lite";
import {
  DownloadCSV,
  DownloadCSVResponse,
  DownloadZIP,
  DownloadZIPResponse,
  DownloadMovie,
  DownloadMovieResponse
} from "@/api/response";

const camelCase = (p: string): string =>
  p.replace(/_./g, s => s.charAt(1).toUpperCase());

const snakeCase = (p: string): string =>
  p.replace(/([A-Z])/g, s => `_${s.charAt(0).toLowerCase()}`);

const isObject = (target: any): boolean =>
  Object.prototype.toString
    .call(target)
    .slice(8, -1)
    .toLowerCase() === "object";

const convertSnakeToCamel = (target: any): void => {
  if (Array.isArray(target)) {
    target.forEach(t => convertSnakeToCamel(t));
  }
  if (isObject(target)) {
    Object.keys(target).forEach(key => {
      if (isObject(target[key]) || Array.isArray(target[key])) {
        convertSnakeToCamel(target[key]);
      }
      // スネークケースをキャメルケースに変換する
      if (key.includes("_")) {
        target[camelCase(key)] = target[key];
        delete target[key];
      }
    });
  }
};

const convertCamelToSnake = (target: any): void => {
  if (Array.isArray(target)) {
    target.forEach(t => convertCamelToSnake(t));
  }
  if (isObject(target)) {
    Object.keys(target).forEach(key => {
      if (isObject(target[key]) || Array.isArray(target[key])) {
        convertCamelToSnake(target[key]);
      }
      // キャメルケースにスネークケース変換する
      if (/[A-Z]/.test(key)) {
        target[snakeCase(key)] = target[key];
        delete target[key];
      }
    });
  }
};

// application/x-www-form-urlencoded用のaxios
const headers = {
  "Content-Type": "application/json",
  "Accept": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  "Access-Control-Allow-Credentials": true,
  "Access-Control-Allow-Headers": "Content-Type, Accept, X-Requested-With",
};
const baseDomain = process.env.VUE_APP_API_BASE_URL;
const baseURL: string = `${baseDomain}/api`;
const request: AxiosInstance = axios.create({
  baseURL,
  headers
} as AxiosRequestConfig);

request.interceptors.request.use(config => {
  const accessToken = LoginStorage.getAccessToken();
  if (accessToken) {
    config.headers["Authorization"] = accessToken;
  }
  if (config.method === "get" && config.params) {
    config.params = cloneDeep(config.params);
    convertCamelToSnake(config.params);
  } else if (config.data) {
    config.data = cloneDeep(config.data);
    convertCamelToSnake(config.data);
  }
  return config;
});

request.interceptors.response.use(response => {
  if (response.data) {
    convertSnakeToCamel(response.data);
  }
  return response;
});

// multipart/form-data用のaxios
const multipartHeaders = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  "Access-Control-Allow-Credentials": true,
  "Access-Control-Allow-Headers": "Content-Type, Accept, X-Requested-With",
};
const multipartRequest: AxiosInstance = axios.create({
  baseURL,
  multipartHeaders
} as AxiosRequestConfig);

multipartRequest.interceptors.request.use(config => {
  const accessToken = LoginStorage.getAccessToken();
  if (accessToken) {
    config.headers["Authorization"] = accessToken;
    // 20210121 NEW_DEV-743 cyber 肖 start
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    // 20210121 NEW_DEV-743 cyber 肖 end
  }
  return config;
});

multipartRequest.interceptors.response.use(response => {
  if (response.data) {
    convertSnakeToCamel(response.data);
  }
  return response;
});

const unreachableResponse = {
  data: {
    statusCd: 500,
    message: "通信エラーが発生しました。",
    results: {}
  }
};

const service = {
  async post(url: string, data?: any) {
    let response;
    try {
      if (data && data.page) {
        data = { ...DEFAULT_TABLE_OPTIONS, ...data };
        data.sortKey = snakeCase(data.sortKey);
      }
      Loading.startLoading(url);
      response = await request.post(url, data);
      response = await this.validateResponse(response);
    } catch (e) {
      response = unreachableResponse;
    } finally {
      Loading.endLoading();
    }
    return response;
  },
  async postReceiveCSV(url: string, data?: any) {
    let response;
    try {
      if (data && data.page) {
        data = { ...DEFAULT_TABLE_OPTIONS, ...data };
        data.sortKey = snakeCase(data.sortKey);
      }
      Loading.startLoading(url);
      response = await request.post(url, data, { responseType: "arraybuffer" });
      response.data = await iconv.decode(
        Buffer.from(response.data),
        "windows-31j"
      );
    } catch (e) {
      return unreachableResponse.data as DownloadCSVResponse;
    } finally {
      Loading.endLoading();
    }
    try {
      // HACK: JSON形式で返却された場合はエラーでそのまま返却する
      response.data = JSON.parse(response.data);
    } catch (e) {
      // HACK: JSON形式でなかった場合は成功
      response.data = {
        statusCd: 200,
        message: "",
        results: {
          fileName: this.extractFileName(response, url),
          csvString: response.data
        } as DownloadCSV
      } as DownloadCSVResponse;
    }
    return response.data;
  },
  async postReceiveZIP(url: string, data?: any) {
    let response;
    try {
      if (data && data.page) {
        data = { ...DEFAULT_TABLE_OPTIONS, ...data };
        data.sortKey = snakeCase(data.sortKey);
      }
      Loading.startLoading(url);
      response = await request.post(url, data, { responseType: "arraybuffer" });
      response.data = Buffer.from(response.data);
    } catch (e) {
      return unreachableResponse.data as DownloadCSVResponse;
    } finally {
      Loading.endLoading();
    }
    try {
      // HACK: JSON形式で返却された場合はエラーでそのまま返却する
      response.data = JSON.parse(response.data);
    } catch (e) {
      // HACK: JSON形式でなかった場合は成功
      response.data = {
        statusCd: 200,
        message: "",
        results: {
          fileName: this.extractFileName(response, url),
          zipString: response.data
        } as DownloadZIP
      } as DownloadZIPResponse;
    }
    return response.data;
  },
  async postMultipart(url: string, data: FormData) {
    let response;
    try {
      Loading.startLoading(url);
      response = await multipartRequest.post(url, data);
      response = await this.validateResponse(response);
    } catch (e) {
      response = unreachableResponse;
    } finally {
      Loading.endLoading();
    }
    return response;
  },
  async get(url: string, params?: any) {
    let response;
    try {
      Loading.startLoading(url);
      response = await request.get(url, { params });
      response = await this.validateResponse(response);
    } catch (e) {
      response = unreachableResponse;
    } finally {
      Loading.endLoading();
    }
    return response;
  },
  extractFileName(response: any, url: string): string {
    try {
      const fileNames = response.headers["content-disposition"].match(
        /(?:filename=)(.*)/
      );
      if (fileNames && fileNames.length > 1) {
        return decodeURI(fileNames[1].replace(/"/g, ""));
      } else {
        return "";
      }
    } catch (e) {
      //oem対応でcors関連でcsvファイルがDLできない事象が発生したので暫定対応
      var fileName = url.replace('/', '').replace('-csv', '');
        // 現在日時からタイムスタンプのファイル名を生成
        const d = new Date(); // Today
        const DateTimeFormat = 'YYYYMMDDhhmiss';
        let toFileName = DateTimeFormat
          .replace(/YYYY/g, String(d.getFullYear()))
          .replace(/MM/g, ('0' + (d.getMonth() + 1)).slice(-2))
          .replace(/DD/g, ('0' + d.getDate()).slice(-2))
          .replace(/hh/g, ('0' + d.getHours()).slice(-2))
          .replace(/mi/g, ('0' + d.getMinutes()).slice(-2))
          .replace(/ss/g, ('0' + d.getSeconds()).slice(-2));
      return fileName + '_' + toFileName;  
    }
  },
  async validateResponse(response: any) {
    if (response.status !== 200) {
      response.data = unreachableResponse.data;
      response.data.statusCd = response.status;
    }
    if (response.data.statusCd === 401) {
      let routeName = router.currentRoute.name;
      if (routeName !== "login") {
        await Admin.clear();
        await router.push({ name: "login" });
      }
    }
    return response;
  },
  async postReceiveMovie(url: string, data?: any) {
    let response;
    try {
      if (data && data.page) {
        data = { ...DEFAULT_TABLE_OPTIONS, ...data };
        data.sortKey = snakeCase(data.sortKey);
      }
      Loading.startLoading(url);
      response = await request.post(url, data, { responseType: "arraybuffer" });
      response.data = Buffer.from(response.data);
    } catch (e) {
      return unreachableResponse.data as DownloadMovieResponse;
    } finally {
      Loading.endLoading();
    }
    try {
      // HACK: JSON形式で返却された場合はエラーでそのまま返却する
      response.data = JSON.parse(response.data);
    } catch (e) {
      // HACK: JSON形式でなかった場合は成功
      response.data = {
        statusCd: 200,
        message: "",
        results: {
          fileName: this.extractFileName(response, url),
          movieData: response.data
        } as DownloadMovie
      } as DownloadMovieResponse;
    }
    return response.data;
  },
};

export default service;
