import service from "@/api/service";
import { LoginRequest } from "@/api/admin/request";
import { LoginResponse } from "@/api/admin/response";
import { CommonResponse } from "@/api/response";

/**
 * ログインAPIをコールします。
 *
 * @param loginRequest ログインのリクエストボディ
 * @return LoginResponse
 */
export async function login(loginRequest: LoginRequest) {
  const response = await service.post("/admin-login", loginRequest);
  return response.data as LoginResponse;
}

/**
 * ログアウトAPIをコールします。
 *
 * @return CommonResponse
 */
export async function logout() {
  const response = await service.post("/logout");
  return response.data as CommonResponse;
}
