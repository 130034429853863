/**
 * レスポンスの共通部分
 */
export interface CommonResponse {
  /** ステータスコード 200以外は失敗 */
  statusCd: number;
  /** ステータスコードが200以外の時のエラーメッセージ */
  message: string;
  /** レスポンスデータ */
  results: any;
}

export const isSuccess = (response: CommonResponse) =>
  response && response.statusCd === 200;

export interface DownloadCSVResponse extends CommonResponse {
  results: DownloadCSV;
}

export interface DownloadCSV {
  fileName: string;
  csvString: string;
}

export interface DownloadZIPResponse extends CommonResponse {
  results: DownloadZIP;
}

export interface DownloadZIP {
  fileName: string;
  zipString: string;
}

export interface DownloadMovieResponse extends CommonResponse {
  results: DownloadMovie;
}

export interface DownloadMovie {
  fileName: string;
  movieData: string;
}
