
































import { Component, Vue, Watch } from "vue-property-decorator";
import Flash from "@/store/common/flash";

@Component
export default class UIAlert extends Vue {
  // ------------
  // 変動値
  // ------------
  // アラートに表示するテキスト
  message = "";
  messageSuccess = "";
  // アラートの種類
  type = "success" as "success" | "error";

  // アラートにリロードボタンを表示するフラグ
  showReloadButton = false;

  // closeボタンの押下フラグ
  closeButtonClicked = false;

  // setTimeoutの戻り値（アラート表示）
  paramsUpdateTimerId = 0;

  // setTimeoutの戻り値（アラート非表示）
  disableTimerId = 0;
  // ------------

  /**
   * アラートメッセージ
   */
  get alertMessage() {
    return Flash.getMessage;
  }

  /**
   * アラートを表示するか否か
   */
  get showAlert() {
    return this.message.length > 0 && !this.closeButtonClicked;
  }

  /**
   * successアラートの表示フラグ
   */
  get alertTypeSuccess() {
    return this.type === "success";
  }

  /**
   * errorアラートの表示フラグ
   */
  get alertTypeError(): boolean {
    return this.type === "error";
  }

  /**
   * アラートの背景色
   */
  get color(): string {
    if (this.alertTypeSuccess) {
      return "blue lighten-3";
    }
    if (this.alertTypeError) {
      return "red lighten-1";
    }
    return "";
  }

  /**
   * createdライフサイクルフック（flashメッセージのrestore）
   */
  async created() {
    await Flash.restore();
  }

  /**
   * alertMessageが更新された場合はdataを更新する
   */
  @Watch("alertMessage")
  watchAlertMessage() {
    if (Flash.getConsumePath !== "" || this.message === Flash.getMessage) {
      return;
    }
    this.updateAlertParams();
  }

  /**
   * 画面遷移した場合、FlashのalertParamsに応じてアラートの表示/非表示を制御する
   */
  @Watch("$route")
  watchRoute() {
    if (Flash.getConsumePath === this.$router.currentRoute.path) {
      this.updateAlertParams();
      return;
    }
    Flash.clear();
  }

  /**
   * FlashのalertParamsをdataに代入する
   */
  updateAlertParams() {
    clearTimeout(this.paramsUpdateTimerId);
    this.paramsUpdateTimerId = window.setTimeout(() => {
      this.message = Flash.getMessage;
      this.messageSuccess = Flash.getMessageSuccessBasic;
      this.type = Flash.getType;
      this.showReloadButton = Flash.getShowAlertButton;
      this.closeButtonClicked = false;
      window.scrollTo(0, 0);
      this.setDisableTimer();
    }, 250);
  }

  /**
   * アラートを非表示にするタイマーをセットする
   */
  setDisableTimer() {
    clearTimeout(this.disableTimerId);
    this.closeButtonClicked = false;
    if (this.alertTypeSuccess) {
      this.disableTimerId = window.setTimeout(() => {
        this.closeButtonClicked = true;
        Flash.clear();
      }, 5000);
    }
  }

  /**
   * アラートを非表示にする
   */
  close() {
    Flash.clear();
    this.closeButtonClicked = true;
  }

  /**
   * 現在のページを再読み込みする
   */
  reload() {
    Flash.clear();
    this.$router.go(0);
  }
}
