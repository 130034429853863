import service from "@/api/service";
import {
  GetResponse,
  GetUnapprovedCount,
  GetUnreadCount
} from "@/api/side-menu/response";

/**
 * サイドメニュー情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-side-menu");
  return response.data as GetResponse;
}

/**
 * 予約未承認数取得APIをコールします。
 *
 * @return GetUnapprovedCount
 */
export async function getUnapprovedCount() {
  const response = await service.post("/get-unapprove-count");
  return response.data as GetUnapprovedCount;
}

/**
 * トーク未読数取得APIをコールします。
 *
 * @return GetUnreadCount
 */
export async function getUnreadCount() {
  const response = await service.post("/get-unread-count");
  return response.data as GetUnreadCount;
}
